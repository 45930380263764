import {
  ILogEventBase,
  TPlan,
  TUserRole,
  systemRights,
} from "@baplie-viewer2/tedivo-api-models";
import awsCognito, { IAwsAuthResponse } from "./awsCognito";

import Services from "../services";
import { errorTracking } from "../tracking/errorTracking";
import { setPreferencesKeyAndValue } from "@baplie-viewer2/tedivo-preferences";

export declare interface ISecurityModule {
  addEventListener(event: "loggedIn", listener: (ev: Event) => void): this;
  addEventListener(event: "loggedOut", listener: (ev: Event) => void): this;
  addEventListener(
    event: "userDataRetrieved",
    listener: (ev: Event) => void,
  ): this;
  addEventListener(
    event: "sessionTimedOut",
    listener: (ev: Event) => void,
  ): this;
  addEventListener(event: "ajaxError", listener: (ev: Event) => void): this;
  addEventListener(
    event: "httpCallProhibited",
    listener: (ev: Event) => void,
  ): this;
  addEventListener(event: string, listener: () => void): this;
}

export class SecurityModule extends EventTarget {
  constructor() {
    super();

    awsCognito.addEventListener("authenticationChanged", (ev) => {
      const { isLoggedIn } = ev.detail;
      window.setTimeout(() => {
        const ev = isLoggedIn ? "loggedIn" : "loggedOut";
        this.dispatchEvent(new CustomEvent(ev));
      }, 0);
    });

    awsCognito.addEventListener("userDataRetrieved", () => {
      this.passInfoToTrackers(awsCognito.userEditableDetails?.email || "");
      this.dispatchEvent(new CustomEvent("userDataRetrieved"));
    });

    awsCognito.addEventListener("sessionTimedOut", () => {
      this.dispatchEvent(new CustomEvent("sessionTimedOut"));
    });
  }

  get isLoggedIn(): boolean {
    return awsCognito.isLoggedIn;
  }

  get idToken(): string | undefined {
    return awsCognito.idToken;
  }

  get initials(): string {
    if (!this.isLoggedIn) return "-";

    const userData = awsCognito.userEditableDetails;
    return `${userData?.name?.[0] || "?"}${userData?.familyName?.[0] || "?"}`;
  }

  /** Cognito Username (sub) */
  get userSub(): string {
    if (!this.isLoggedIn) return "";
    return awsCognito.userSub || "";
  }

  /** The first name of the logged in user */
  get name(): string {
    if (!this.isLoggedIn) return "";
    return awsCognito.userEditableDetails.name || "";
  }

  /** The family name of the logged in user */
  get familyName(): string {
    if (!this.isLoggedIn) return "";
    return awsCognito.userEditableDetails.familyName || "";
  }

  /** The display name of the logged in user */
  get displayName(): string {
    if (!this.isLoggedIn) return "";
    const userDetails = awsCognito.userEditableDetails;
    return `${userDetails?.name || ""} ${userDetails?.familyName || ""}`;
  }

  get email(): string {
    if (!this.isLoggedIn) return "";
    return awsCognito.userEditableDetails.email || "";
  }

  get userRole(): TUserRole | undefined {
    if (!this.isLoggedIn) return undefined;
    return awsCognito.currentOrgSecurityData?.role || "USER";
  }

  get currentOrganizationId(): string {
    if (!this.isLoggedIn) return "";
    return awsCognito.currentOrgSecurityData?.orgId || "";
  }

  get currentOrganizationName(): string {
    if (!this.isLoggedIn) return "";
    return awsCognito.currentOrgSecurityData?.name || "";
  }

  get currentOrganizationPlanId(): TPlan | "" {
    if (!this.isLoggedIn) return "";
    return awsCognito.currentOrgSecurityData?.planId || "FREE";
  }

  get currentOrganizationMaxFreeTrialFiles(): number {
    if (!this.isLoggedIn) return 3;
    return awsCognito.currentOrgSecurityData?.freeTrialMax || 3;
  }

  get organizationMfaForAllUsers() {
    return awsCognito.orgMfaForAllUsers;
  }

  get isLoginInfoComplete() {
    return awsCognito.isLoginInfoComplete;
  }

  get isMfaEnabled() {
    return awsCognito.isMfaEnabled;
  }

  get orgAllowedDomains(): string[] {
    return awsCognito.orgAllowedDomains;
  }

  get planIsExpired(): boolean {
    if (awsCognito.currentOrgSecurityData?.expired === "1") return true;
    return false;
  }

  get planExpirationDate(): Date | undefined {
    const expDate = awsCognito.currentOrgSecurityData?.expDate;
    return expDate ? new Date(expDate) : undefined;
  }

  get planIsReadOnly(): boolean {
    return this.planIsExpired;
  }

  get sessionIsExpired(): boolean {
    const sessionExpires = awsCognito.sessionExpires;
    if (!sessionExpires) return true;
    return sessionExpires < new Date();
  }

  getBeaconMetadata(): Omit<ILogEventBase, "itemId"> {
    return {
      date: new Date(),
      userSub: awsCognito.userSub || "",
      organizationId: this.currentOrganizationId,
    };
  }

  passInfoToTrackers(email: string) {
    if (this.isLoggedIn) {
      errorTracking.identify(
        this.userSub,
        email,
        this.displayName,
        this.currentOrganizationName,
      );
    } else {
      // We want to know who was using it. So we don't delete info when logged out.
    }
  }

  async authenticate(
    email: string,
    password: string,
  ): Promise<IAwsAuthResponse> {
    const emailClean = email.trim().toLowerCase();
    const passwordClean = password.trim();

    const resp = await awsCognito.authenticateUser(emailClean, passwordClean);
    setPreferencesKeyAndValue(
      "currentUsername",
      this.isLoggedIn ? emailClean : "",
    );

    return resp;
  }

  async setNewPassword(
    email: string,
    password: string,
    acceptsMarketingMails: boolean,
  ): Promise<IAwsAuthResponse> {
    const resp = await awsCognito.setNewPassword(email, password);

    setPreferencesKeyAndValue(
      "currentUsername",
      awsCognito.isLoggedIn ? email : "",
    );

    awsCognito.tempAcceptsMarketingMails = acceptsMarketingMails;
    return resp;
  }

  async signOut() {
    setPreferencesKeyAndValue("currentUsername", "");
    await awsCognito.signOut();
    this.passInfoToTrackers("");
  }

  changePassword = (oldPassword: string, newPassword: string) =>
    awsCognito.changePassword(this.userSub, oldPassword, newPassword);

  clearSession() {
    awsCognito.clearSession();
  }

  async checkIfUserIsLoggedIn(): Promise<boolean> {
    return await awsCognito.checkIfUserIsLoggedIn();
  }

  userHasPermission(right: string) {
    if (right === systemRights.ANONYMOUS) return true;
    if (right === systemRights.NOT_LOGGED_IN) return !this.isLoggedIn;

    return this.userHasSystemRight(right);
  }

  private userHasSystemRight(right: string): boolean {
    const rights = awsCognito.currentOrgSecurityData?.rights;

    if (!rights) return false;

    return rights.indexOf(right) >= 0;
  }

  get userIsTedivoAdmin() {
    return this.userHasSystemRight(systemRights.ORG.IsTedivoAdmin);
  }

  updateUserDetails(
    name: string,
    familyName: string,
    email: string,
    marketingMails: boolean,
  ) {
    awsCognito.updateUserDetails(name, familyName, email, marketingMails);
  }

  updateOrganizationDetails(organizationName: string, mfaForAllUsers: boolean) {
    awsCognito.updateOrganizationName(organizationName);
    awsCognito.orgMfaForAllUsers = mfaForAllUsers;
  }

  xhrRequestFired = () => {
    awsCognito.initiateRefreshToken();
  };

  public getOrganizationUsersBySub = async (
    orgId: string,
  ): Promise<Record<string, string> | undefined> => {
    if (!this.isLoggedIn) return undefined;
    if (awsCognito.orgUsersBySub) {
      return awsCognito.orgUsersBySub;
    } else {
      const resp = await Services.accounts.getMyOrganizationUsers(orgId);
      awsCognito.orgUsersBySub = resp.data;
      return resp.data;
    }
  };

  public associateMfaDevice = awsCognito.associateMfaDevice;
  public verifyAndFinishMfaDeviceAssociation =
    awsCognito.verifyAndFinishMfaDeviceAssociation;
}

const securityModule = new SecurityModule();
if (
  process.env.NX_PUBLIC_STAGE === "dev" ||
  process.env.NX_PUBLIC_STAGE === "alpha"
)
  window.securityModule = securityModule;

export default securityModule;
