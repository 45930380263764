import IntegratedDialog from "./IntegratedDialog";

export default class IntegratedDialogError extends IntegratedDialog {
  constructor(parentNode: HTMLElement, removeDialogWhenHidden?: boolean) {
    super({
      parentNode,
      removeDialogWhenHidden,
      buttonsAttrs: [
        {
          label: "general:common.close",
          value: "close",
          type: "submit",
          autofocus: true,
        },
      ],
    });
  }

  get onDismiss() {
    return this.onButtonClicked;
  }

  set onDismiss(
    v: ((v: string) => boolean | Promise<boolean> | void) | undefined,
  ) {
    this.onButtonClicked = v;
  }
}
