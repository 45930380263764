import { __awaiter, __rest } from "tslib";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import EditDrawer from "../../common/EditDrawer";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import SmartTable from "../../common/smartTable/smart-table.element";
import { formatDateInCurrentTimezone } from "@baplie-viewer2/tedivo-pure-helpers";
import globalStore from "../../../app/stores/globalStore";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
import { z } from "zod";
export class TVDAdminOrgsComponent extends HTMLElement {
    constructor() {
        super();
        this.displayOrganizations = () => {
            globalStore.touchLastUserInteraction();
            const wrapper = this.wrapper;
            const box = this.mainBox;
            removeChildren(box);
            const actionAddOrgButton = document.createElement("sl-button");
            actionAddOrgButton.innerHTML = getTranslation("general:organizations.adminCreate");
            actionAddOrgButton.variant = "primary";
            actionAddOrgButton.addEventListener("click", () => {
                this.openOrganizationEditModal();
            }, false);
            wrapper.setLoading(true);
            Services.organizations
                .adminGetAllOrganizations()
                .then((resp) => {
                if (resp.data) {
                    box.appendChild(createOrgsTable(resp.data, actionAddOrgButton, (org) => {
                        this.openOrganizationEditModal(Object.assign({}, org));
                    }, this.deleteOrganization));
                }
            })
                .catch((e) => {
                this.showError(e);
            })
                .finally(() => {
                wrapper.setLoading(false);
            });
        };
        this.showError = (e) => {
            this.dialogError.show(getTranslation(`errors:errorHasOcurred`), getTranslation(typeof e === "string" ? e : e.translationKey));
            console.error(e);
        };
        this.openOrganizationEditModal = (prevData) => {
            const editForm = createOrgEdit(this.editDrawer.submitButton, prevData);
            if (editForm) {
                const drawer = this.editDrawer.getEditDrawer({
                    title: prevData
                        ? getTranslation("general:organizations.adminEdit")
                        : getTranslation("general:organizations.adminCreate"),
                    showUnits: false,
                    readOnlyMode: false,
                    onSave: () => __awaiter(this, void 0, void 0, function* () {
                        this.editDrawer.disabled = true;
                        const valid = yield editForm.submitFunction();
                        this.editDrawer.disabled = false;
                        if (valid) {
                            this.displayOrganizations();
                        }
                        return valid;
                    }),
                });
                drawer.appendChild(editForm.node);
                drawer.show();
            }
        };
        this.deleteOrganization = (id, dta) => {
            if (id === securityModule.currentOrganizationId)
                return;
            const dialog = this.deleteDialog;
            removeChildren(dialog);
            dialog.label = getTranslation("general:organizations.delete");
            const htmlContent = document.createElement("div");
            htmlContent.innerHTML = `${getTranslation("general:organizations.delete")}<br /><br /><strong>${dta.name}</strong>`;
            const closeBtn = document.createElement("sl-button");
            closeBtn.slot = "footer";
            closeBtn.variant = "danger";
            closeBtn.autofocus = true;
            closeBtn.tabIndex = 0;
            closeBtn.innerHTML = getTranslation("general:common.delete");
            closeBtn.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
                closeBtn.disabled = true;
                yield Services.organizations.adminDeleteOrganization(id);
                dialog.hide();
                this.displayOrganizations();
            }), false);
            dialog.appendChild(htmlContent);
            dialog.appendChild(closeBtn);
            this.appendChild(dialog);
            dialog.show();
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.dialogError = new IntegratedDialogError(this);
        this.wrapper = document.createElement("div-spinner-element");
        this.mainBox = document.createElement("div");
        this.mainBox.className = "oss-card";
        this.deleteDialog = document.createElement("sl-dialog");
        this.editDrawer = new EditDrawer();
        setAppTitle(getTranslation("general:organizations.organizations"));
    }
    connectedCallback() {
        goSquared.trackPage("TEDIVO Admin Pages - Organizations");
        goSquared.addEvent("Admin/Organizations - Show page");
        const wrapper = this.wrapper, h1 = document.createElement("h1");
        wrapper.appendChild(h1);
        wrapper.appendChild(this.mainBox);
        this.appendChild(this.editDrawer.drawer);
        this.appendChild(wrapper);
        this.displayOrganizations();
        this.i18nCR.addConsumer(topMessageElement.element, "general:adminArea", "innerHTML");
        this.i18nCR.addConsumer(h1, "general:organizations.organizations", "innerHTML");
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
}
TVDAdminOrgsComponent.observedAttributes = [];
customElements.define("tvd-admin-orgs-component", TVDAdminOrgsComponent);
function createOrgsTable(organizations = [], actionBox, onEditOrganization, onDeleteOrganization) {
    const createSortIcon = (asc) => {
        const icon = document.createElement("sl-icon");
        icon.name = asc ? "sort-down-alt" : "sort-up-alt";
        return icon;
    };
    const tableUsers = new SmartTable().initialize({
        className: "tvd-table tvd-table-smart-table",
        data: organizations,
        defaultSort: "name",
        fields: [
            {
                name: "name",
                label: getTranslation("general:common.name"),
            },
            {
                name: "planId",
                label: getTranslation("general:organizations.plan"),
                mapper: (org) => getTranslation(`enums:OrgPlanTypeEnum.${org.planId}`),
            },
            {
                name: "enabled",
                label: getTranslation("general:common.status"),
                mapper: (org) => getTranslation(`general:common.${org.enabled ? "enabled" : "disabled"}`),
            },
            {
                name: "usersCount",
                label: getTranslation("general:users.users"),
                className: "centered",
                mapper: (org) => {
                    const btn = document.createElement("sl-button");
                    btn.type = "button";
                    btn.addEventListener("click", () => {
                        router.navigate(routeFns.adminOrganizationUsers(org.organizationId));
                    }, false);
                    btn.size = "small";
                    btn.variant = "primary";
                    btn.pill = true;
                    btn.innerHTML = String(org.usersCount || 0);
                    return btn;
                },
            },
            {
                name: "createdAt",
                label: getTranslation("general:common.createdAt"),
                valueFunction: (dta) => formatDateInCurrentTimezone(dta.createdAt),
                className: "centered",
            },
            {
                name: "modifiedAt",
                label: getTranslation("general:common.modifiedAt"),
                valueFunction: (dta) => formatDateInCurrentTimezone(dta.modifiedAt),
                className: "centered",
            },
            {
                name: "organizationId",
                label: getTranslation("general:common.expirationDate"),
                valueFunction: (dta) => formatDateInCurrentTimezone(dta === null || dta === void 0 ? void 0 : dta.expiresAt, true),
                className: "centered",
            },
            {
                name: "organizationId",
                label: getTranslation("general:common.vesselFiles"),
                notSortable: true,
                className: "centered",
                mapper: (org) => {
                    var _a, _b, _c;
                    const btn = document.createElement("sl-button");
                    btn.type = "button";
                    btn.addEventListener("click", () => {
                        router.navigate(routeFns.theirCloud(org.organizationId), {
                            organizationName: org.name,
                        });
                    }, false);
                    btn.size = "small";
                    btn.variant = "success";
                    btn.pill = true;
                    btn.title = createAltText(org.filesCount || {});
                    btn.innerHTML = `${((_a = org.filesCount) === null || _a === void 0 ? void 0 : _a.draft) || "-"}/${((_b = org.filesCount) === null || _b === void 0 ? void 0 : _b.private) || "-"}/${((_c = org.filesCount) === null || _c === void 0 ? void 0 : _c.community) || "-"}`;
                    return btn;
                },
            },
            {
                name: "organizationId",
                label: getTranslation("general:common.edit"),
                type: "icon",
                icon: "pencil",
                notSortable: true,
                onClick: (dta) => {
                    onEditOrganization(dta);
                },
            },
            {
                name: "organizationId",
                label: getTranslation("general:common.delete"),
                type: "icon",
                icon: (dta) => dta.organizationId !== securityModule.currentOrganizationId
                    ? "trash"
                    : "",
                iconVariant: "danger",
                notSortable: true,
                onClick: (dta) => {
                    onDeleteOrganization(dta.organizationId, dta);
                },
            },
        ],
        settings: {
            sortEnabled: true,
            sortVisible: true,
            filterEnabled: true,
            labelSortBy: getTranslation("general:common.sortBy"),
            labelSearch: getTranslation("general:common.filterBy"),
            createSortIconFn: createSortIcon,
            filterFields: ["name"],
            actionBox,
        },
        pkFunction: (dta) => dta.organizationId,
    });
    return tableUsers;
}
function createFormValidator() {
    const formValidator = z.object({
        name: z.string().min(1),
        enabled: z.boolean(),
        planId: z.string(),
        domains: z.string(),
        freeTrialMax: z.number().min(1),
    });
    return formValidator;
}
function createOrgEdit(submitButton, prevData) {
    var _a, _b, _c;
    const holder = document.createElement("div");
    const formFields = [
        {
            name: "name",
            label: "general:common.name",
            type: "textBox",
            initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.name) || "",
        },
        {
            name: "planId",
            label: "general:organizations.plan",
            type: "radioButtonList",
            fieldset: true,
            options: [
                {
                    value: "FREE",
                    label: getTranslation("enums:OrgPlanTypeEnum.FREE"),
                },
                {
                    value: "PAID1",
                    label: getTranslation("enums:OrgPlanTypeEnum.PAID1"),
                },
                {
                    value: "PAID2",
                    label: getTranslation("enums:OrgPlanTypeEnum.PAID2"),
                },
            ],
            initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.planId) || "FREE",
        },
        {
            name: "freeTrialMax",
            label: "general:organizations.freeTrialMax",
            type: "number",
            initialValue: (_a = prevData === null || prevData === void 0 ? void 0 : prevData.freeTrialMax) !== null && _a !== void 0 ? _a : 3,
            helpText: "Minimum 1",
        },
        {
            name: "domains",
            label: "general:organizations.domains",
            type: "textArea",
            helpText: "general:organizations.domainsHelp",
            initialValue: ((_b = prevData === null || prevData === void 0 ? void 0 : prevData.domains) === null || _b === void 0 ? void 0 : _b.join("\n")) || "",
        },
        [
            {
                name: "expirationDate",
                type: "date",
                label: "general:common.expirationDate",
                initialValue: prevData === null || prevData === void 0 ? void 0 : prevData.expiresAt,
            },
            {
                name: "enabled",
                label: "general:common.enabled",
                type: "checkbox",
                initialValue: (_c = prevData === null || prevData === void 0 ? void 0 : prevData.enabled) !== null && _c !== void 0 ? _c : true,
                padStart: true,
            },
        ],
    ];
    const tedivoForm = new TedivoForm({
        fields: formFields,
        onSubmit: () => undefined,
        formValidator: createFormValidator(),
        submitButton: submitButton,
        autoFocusOnFirstInput: true,
        hiddenData: Object.assign(Object.assign({}, prevData), { domains: (prevData === null || prevData === void 0 ? void 0 : prevData.domains.join(",")) || "" }),
    });
    translateTedivoForm({
        tedivoForm,
        getTranslation: getTranslation,
    });
    holder.appendChild(tedivoForm.form);
    return {
        node: holder,
        tedivoForm,
        submitFunction: submitPassedToEditDrawer,
    };
    function submitPassedToEditDrawer() {
        return __awaiter(this, void 0, void 0, function* () {
            const validResult = tedivoForm.doSubmitForm();
            if (validResult.success) {
                const values = tedivoForm.getValues();
                const { domains: domainsStr, expirationDate } = values, rest = __rest(values, ["domains", "expirationDate"]);
                const domains = (domainsStr || "")
                    .split("\n")
                    .map((f) => f.trim().toLowerCase().replace(/^\./, "").replace(/\.$/, ""))
                    .filter(Boolean);
                if (prevData === null || prevData === void 0 ? void 0 : prevData.organizationId) {
                    yield Services.organizations.adminUpdateOrganization(Object.assign(Object.assign(Object.assign({}, prevData), rest), { organizationId: prevData.organizationId, expiresAt: expirationDate, domains }));
                }
                else {
                    const updRecord = Object.assign(Object.assign({}, rest), { domains, expiresAt: expirationDate });
                    yield Services.organizations.adminUpdateOrganization(updRecord);
                }
                return true;
            }
            else {
                return false;
            }
        });
    }
}
function createAltText(filesCount) {
    return `${getTranslation("enums:FileStateEnum.DRAFT")}: ${(filesCount === null || filesCount === void 0 ? void 0 : filesCount.draft) || "0"}
${getTranslation("enums:FileStateEnum.ORG_READY")}: ${(filesCount === null || filesCount === void 0 ? void 0 : filesCount.private) || "0"}
${getTranslation("enums:FileStateEnum.COMMUNITY")}: ${(filesCount === null || filesCount === void 0 ? void 0 : filesCount.community) || "0"}`;
}
